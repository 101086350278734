const axios = require('axios');


const reparacionesService = {

    getReparaciones(usuario_id) {
        return axios.get(`${process.env.VUE_APP_API_URL}api/reparaciones/usuario/${usuario_id}`,{
            headers: {
            'x-token-clica': 'agenciaclica123'
            }
        });
    },

    getReparacion(reparacion_id) {
        return axios.get(`${process.env.VUE_APP_API_URL}api/reparaciones/${reparacion_id}`,{
            headers: {
            'x-token-clica': 'agenciaclica123'
            }
        });
    },

    createReparacion(reparacion) {
        return axios.post(`${process.env.VUE_APP_API_URL}api/reparaciones/`, reparacion,
        {
            headers: {
            'x-token-clica': 'agenciaclica123'
            }
        });
    },

    updateReparacion(reparacion_id, reparacion) {
        return axios.put(`${process.env.VUE_APP_API_URL}api/reparaciones/${reparacion_id}`,reparacion,{
            headers: {
                'x-token-clica': 'agenciaclica123'
            }
        });
    },

    deleteReparacion(reparacion_id) {
        return axios.delete(`${process.env.VUE_APP_API_URL}api/reparaciones/${reparacion_id}`,{
            headers: {
                'x-token-clica': 'agenciaclica123'
            }
        });
    },

    incrementReparacionesPrice(usuario_id, data) {
        return axios.post(`${process.env.VUE_APP_API_URL}api/bulk/reparaciones/increment?usuario_id=${usuario_id}`, data,
        {
            headers: {
                'x-token-clica': 'agenciaclica123'
            }
        });
    },

    reduceReparacionesPrice(usuario_id, data) {
        return axios.post(`${process.env.VUE_APP_API_URL}api/bulk/reparaciones/reduce?usuario_id=${usuario_id}`, data,
            {
                headers: {
                    'x-token-clica': 'agenciaclica123'
                }
            });
    },
}

module.exports = reparacionesService;