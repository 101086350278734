<template>
  <div class="list reparaciones">
    <CRow>
      <CCol sm="12">
        <div class="list__actions">
          <router-link to="/reparaciones/nuevaReparacion"><CButton  color="success"><CIcon name="cil-plus" /> Agregar nuevo</CButton></router-link>
          <CButton progress :spinner="false" timeout="500" color="info" :onChange="isLoading" v-on:click="reloadData()"><CIcon name="cil-sync" />Actualizar datos</CButton>
        </div><!-- list__actions -->
        <div class="list__table">
          <CDataTable :alignVertical="true"
            :items="reparaciones"
            :fields="fields"
            :items-per-page="25"
            :columnFilter="true"
            :items-per-page-select="{label: 'Items por página', values: [25, 50, 100, 500, 1000]}"
            :tableFilter="{ external: false, lazy: true, placeholder: 'Filtrar reparaciones', label: 'Filtro de tabla' }"
            :sorterValue="{ asc: true }"
            :sorter='{ external: false, resetable: true }'
            :loading="isLoading"
            pagination
          >
          <template #marca="{item}">
            <td>
              <span>{{item.Modelo.Marca.name}}</span>
            </td>
          </template>
          <template #price="{item}">
            <td class="price__td" >
              <span v-on:click="changeUpdatingPriceStatus(item)" v-if="!item.updatingPrice" >${{item.price.toLocaleString()}}</span>
              <span v-else>
                <div class="price_update">
                  <CInput v-model="item.price"
                    type="number"
                    autocomplete="reparacionPrice"
                    placeholder=""
                    required
                  />
                  <CButtonGroup >
                    <CButton size="sm" v-on:click="updateItemPrice(item)" color="success"><CIcon name="cil-check-alt" /></CButton>
                  </CButtonGroup>
                </div>
              </span>
            </td>
          </template>
          <template #stock="{item}">
            <td>
              <CBadge :color="getBadge(item.stock)">{{parseStock(item.stock)}}</CBadge>
            </td>
          </template>
          <template #createdAt="{item}">
            <td>
              <span>{{new Date(item.createdAt).getDate() + '/' +(new Date(item.createdAt).getMonth() + 1 ) + '/'+new Date(item.createdAt).getFullYear()+ ' ' +  new Date(item.createdAt).getHours() + ':' + new Date(item.createdAt).getMinutes()}}</span>
            </td>
          </template>
          <template #acciones="{item}">
            <td>
              <CButtonGroup >
                <CButton v-on:click="triggerUpdateReparacion(item)" v-c-tooltip="'Editar'" color="info"><CIcon name="cil-pencil" /></CButton>
                <CButton v-on:click="triggerDeleteReparacion(item)" v-c-tooltip="'Eliminar'" color="danger"><CIcon name="cil-x-circle" /></CButton>
              </CButtonGroup>
            </td>
          </template>
          </CDataTable>
        </div><!-- list__table -->
      </CCol>
    </CRow>
  </div>
</template>

<script>
const reparacionesService = require('../../services/reparaciones.service');
const Swal = require('sweetalert2');

export default {
  name: 'Reparaciones',
  data() {
    return {
      fields: this.generateFields(),
      reparaciones: [{}],
      modelos: [{}],
      marcas: [{}],
      isLoading: true,
    }
  },
  created() {
    this.getReparaciones();
  },
  updated() {

  },
  methods: {
    getReparaciones: function() {
      reparacionesService.getReparaciones(this.$store.state.user.data.id).then((response) => {
        // handle success
        this.reparaciones = response.data.reparaciones;
        this.isLoading = false;
        this.addUpdatingPriceFlag();
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
    },
    addUpdatingPriceFlag: function(){
      this.reparaciones.forEach((reparacion) => {
        reparacion['updatingPrice'] = false;
      });
    },
    changeUpdatingPriceStatus: function(item){
      this.addUpdatingPriceFlag();
      item.updatingPrice = true;
      let reparaciones = this.reparaciones;
      this.reparaciones = '';
      this.reparaciones = reparaciones;
    },
    disableUpdatingPriceField: function(item){
      this.addUpdatingPriceFlag();
      item.updatingPrice = false;
      let reparaciones = this.reparaciones;
      this.reparaciones = '';
      this.reparaciones = reparaciones;
    },
    reloadData: function() {
      this.isLoading = true;
      this.getReparaciones();
    },
    generateFields(){
      const fields = [{
        key: 'name' ,
        label: 'Reparación',
      },{
        key: 'modelo_name' ,
        label: 'Modelo',
      },{
        key: 'marca' ,
        label: 'Marca',
        filter: false,
        sorter: false
      },{
        key: 'price' ,
        label: 'Precio',
        filter: false,
      },{
        key: 'stock' ,
        label: 'Stock',
        filter: false,
      },{
        key: 'createdAt' ,
        label: 'Fecha',
        filter: false,
      },{
        key: 'acciones' ,
        label: 'Acciones',
        _style: 'width: 100px;',
        filter: false,
        sorter: false
      }];

      return fields;
    },
    getBadge (status) {
      return status === true ? 'success'
          : status === false ? 'danger' : 'secondary'
    },
    parseStock (stock) {
      return stock === true ? 'En stock'
        : stock === false ? 'Sin stock' : ''
    },
    async updateItemPrice(reparacion){
      reparacionesService.updateReparacion(reparacion.id, reparacion).then(() => {
        Swal.fire('¡Operación realizada!', 'La reparacion fue actualizada', 'success');
        this.disableUpdatingPriceField(reparacion);
      }).catch((error) => {
        // handle error
        console.log(error);
        Swal.fire('Hubo un error', 'Consulte al administrador', 'error')
      });
    },
    async triggerUpdateReparacion(reparacion){
      //console.log(reparacion.id);
      this.$router.push('/reparaciones/editarReparacion/' + reparacion.id);
    },
    async triggerDeleteReparacion(reparacion){
      await Swal.fire({
        title: 'Eliminar reparacion',
        html: `Vas a eliminar la reparacion: <br>${reparacion.name} - ${reparacion.modelo_name} <br><br><strong>¿Estás seguro?</strong>`,
        showDenyButton: true,
        confirmButtonText: `Confirmar`,
        denyButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          reparacionesService.deleteReparacion(reparacion.id).then(() => {
            Swal.fire('¡Operación realizada!', 'La reparacion fue eliminada', 'success');
            this.reparaciones = this.reparaciones.filter(function(item) {
                return item.id != reparacion.id;
            });
          }).catch((error) => {
            // handle error
            console.log(error);
            Swal.fire('Hubo un error', 'Consulte al administrador', 'error')
          });
        } else if (result.isDenied) {
          Swal.fire('Operación cancelada', '', 'error');
        }
      });
    }
  }
}
</script>
