var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list reparaciones"},[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('div',{staticClass:"list__actions"},[_c('router-link',{attrs:{"to":"/reparaciones/nuevaReparacion"}},[_c('CButton',{attrs:{"color":"success"}},[_c('CIcon',{attrs:{"name":"cil-plus"}}),_vm._v(" Agregar nuevo")],1)],1),_c('CButton',{attrs:{"progress":"","spinner":false,"timeout":"500","color":"info","onChange":_vm.isLoading},on:{"click":function($event){return _vm.reloadData()}}},[_c('CIcon',{attrs:{"name":"cil-sync"}}),_vm._v("Actualizar datos")],1)],1),_c('div',{staticClass:"list__table"},[_c('CDataTable',{attrs:{"alignVertical":true,"items":_vm.reparaciones,"fields":_vm.fields,"items-per-page":25,"columnFilter":true,"items-per-page-select":{label: 'Items por página', values: [25, 50, 100, 500, 1000]},"tableFilter":{ external: false, lazy: true, placeholder: 'Filtrar reparaciones', label: 'Filtro de tabla' },"sorterValue":{ asc: true },"sorter":{ external: false, resetable: true },"loading":_vm.isLoading,"pagination":""},scopedSlots:_vm._u([{key:"marca",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',[_vm._v(_vm._s(item.Modelo.Marca.name))])])]}},{key:"price",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"price__td"},[(!item.updatingPrice)?_c('span',{on:{"click":function($event){return _vm.changeUpdatingPriceStatus(item)}}},[_vm._v("$"+_vm._s(item.price.toLocaleString()))]):_c('span',[_c('div',{staticClass:"price_update"},[_c('CInput',{attrs:{"type":"number","autocomplete":"reparacionPrice","placeholder":"","required":""},model:{value:(item.price),callback:function ($$v) {_vm.$set(item, "price", $$v)},expression:"item.price"}}),_c('CButtonGroup',[_c('CButton',{attrs:{"size":"sm","color":"success"},on:{"click":function($event){return _vm.updateItemPrice(item)}}},[_c('CIcon',{attrs:{"name":"cil-check-alt"}})],1)],1)],1)])])]}},{key:"stock",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CBadge',{attrs:{"color":_vm.getBadge(item.stock)}},[_vm._v(_vm._s(_vm.parseStock(item.stock)))])],1)]}},{key:"createdAt",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',[_vm._v(_vm._s(new Date(item.createdAt).getDate() + '/' +(new Date(item.createdAt).getMonth() + 1 ) + '/'+new Date(item.createdAt).getFullYear()+ ' ' + new Date(item.createdAt).getHours() + ':' + new Date(item.createdAt).getMinutes()))])])]}},{key:"acciones",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CButtonGroup',[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:('Editar'),expression:"'Editar'"}],attrs:{"color":"info"},on:{"click":function($event){return _vm.triggerUpdateReparacion(item)}}},[_c('CIcon',{attrs:{"name":"cil-pencil"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:('Eliminar'),expression:"'Eliminar'"}],attrs:{"color":"danger"},on:{"click":function($event){return _vm.triggerDeleteReparacion(item)}}},[_c('CIcon',{attrs:{"name":"cil-x-circle"}})],1)],1)],1)]}}])})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }